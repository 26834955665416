<script>
import TablePage from '@/found/components/table_page';
import VisitForm from './form_detail.vue';

export default {
  extends: TablePage,
  data() {
    return {
      formConfig: {},
    };
  },
  components: { VisitForm },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = {
      actCode: this.propsObj.row.actCode, userCode: this.propsObj.row.userCode,
    };
    this.getConfigList('visit_execute_list');
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'clientName') {
        this.formName = 'VisitForm';
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看 详情';
        this.openFull();
      }
    },
  },
};
</script>
