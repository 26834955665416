import TablePage from '../../../../../components/table_page';
import UserDetail from './userDetail';

export default {
  name: 'visit_activity_report',
  extends: TablePage,
  data() {
    return {};
  },
  components: {
    UserDetail,
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'marketUserNum') {
        this.formName = 'UserDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '市场支持人员信息';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      if (val.code === 'market_support_personnel_information') {
        this.formName = 'UserDetail';
        this.propsObjInData.row = row;
        this.modalConfig.title = '市场支持人员信息';
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('customer_act_visit_list');
  },

};
